"use client";
import { PropsWithChildren, useState, useEffect } from "react";
import clsx from "clsx";
import { usePathname } from "next/navigation";

import { User } from "@/types";
import { useAuth } from "@/context/AuthContext";

interface StickyNavBarContainerProps extends PropsWithChildren {
  user?: User | null;
}

export function StickyNavBarContainer({ user, children }: Readonly<StickyNavBarContainerProps>) {
  const [hasScrolled, sethasScrolled] = useState(false);
  const pathname = usePathname();
  const isGiftPage = pathname.includes("/v2/gift");
  const isHome = pathname.includes("/v2/home");
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (pathname === "/v2/onboarding") {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    const handleScroll = () => {
      sethasScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={clsx(
        `box-shadow sticky top-0 z-[100] transition-transform duration-300 ease-in-out`,
        (user || isAuthenticated) && !isGiftPage && "bg-surface-default-secondary",
        isHome && "bg-white dark:bg-[var(--color-primary-glo-black)]",
        {
          "bg-surface-default-secondary shadow-nav": hasScrolled
        }
      )}
    >
      {children}
    </div>
  );
}
