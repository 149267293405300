"use client";

// TODO figure out how to import JWPlayer types in Next.js
// import 'jwplayer/index.d.ts';

import { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { createRoot, Root } from "react-dom/client";
import { isAndroid, isFirefox } from "react-device-detect";
import JWPlayer from "@jwplayer/jwplayer-react";
import { v4 as uuidv4 } from "uuid";
import Image from "next/image";

import { applicationSettings, commonConstants } from "@/constants";
import { fetchSoundrackById } from "@/services/media.service";
import { cn } from "@/ui/utils";
import { AudioClassThumbnail } from "@/ui/components/AudioClassThumbnail";

import { SoundMixer } from "./SoundMixer";
import { SoundVolume } from "./SoundVolume";
import { VideoPlayerOverlay } from "./VideoPlayerOverlay";
import { PauseEvent, PlayEvent, SeekEvent, ProgressEvent } from "./VideoPlayerEvents";

const { JWPLAYER_KEY, JWPLAYER_LIBRARY_URL, JWPLAYER_CHROME_CAST_ID } = applicationSettings;

const { PROGRESS_EVENT_TRACKING_INTERVAL } = commonConstants;

import classes from "./VideoPlayer.module.scss";

const Config = {
  key: JWPLAYER_KEY
};

const library = JWPLAYER_LIBRARY_URL;

const ChromecastAppId = JWPLAYER_CHROME_CAST_ID;

const DefaultOptions = {
  androidhls: false,
  aspectratio: "16:9",
  autostart: false,
  captions: null,
  cast: {
    customAppId: ChromecastAppId
  },
  displaytitle: false,
  hlsjsdefault: false,
  playbackRateControls: [0.5, 0.75, 1, 1.25, 1.5, 2],
  playlist: null,
  preload: "auto",
  repeat: false,
  skin: { name: "glo" },
  streching: "fill",
  timeSliderAbove: false,
  tracks: null,
  width: "100%"
};

export interface VideoPlayerSource {
  adroidhls?: boolean;
  default?: boolean;
  file: string;
  hlsjsdefault?: boolean;
  label?: string;
  liveSyncDuration?: number;
  preload?: "metadata" | "auto" | "none";
  type?: string;
}

export interface VideoPlayerRef {
  isPaused: () => boolean;
  isPlaying: () => boolean;
  play: () => void;
  pause: () => void;
}

export interface VideoPlayerProps {
  autoStart?: boolean;
  eventTracking?: {
    play?: (event: PlayEvent, position: number) => Promise<unknown>;
    pause?: (event: PauseEvent, position: number) => Promise<unknown>;
    seek?: (event: SeekEvent) => Promise<unknown>;
    progress?: (event: ProgressEvent) => Promise<unknown>;
  };
  onComplete: () => void;
  overlay?: ReactElement;
  overlayClassName?: string;
  soundtrackId?: string;
  sources: VideoPlayerSource[];
  thumbnailImage?: string;
  videoPlayerContainerStyles?: string; // Video Player Container Styles in CSS format
  isAudio?: boolean;
}

interface HlsVideoPlayerProps {
  androidhls: boolean | null;
  hlsjsdefault: boolean;
}

function calculateHls(): HlsVideoPlayerProps {
  const hlsjsdefault = isAndroid && isFirefox;
  const androidhls = isAndroid ? !isFirefox : null;

  return {
    androidhls,
    hlsjsdefault
  };
}

export const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>((props, ref) => {
  const {
    autoStart = false,
    eventTracking,
    onComplete,
    overlay,
    overlayClassName,
    soundtrackId,
    sources,
    thumbnailImage,
    videoPlayerContainerStyles,
    isAudio
  } = props;
  const [soundVolumeId] = useState(`sound-volume-${uuidv4()}`);
  const [soundMixerId] = useState(`sound-mixer-${uuidv4()}`);
  const [isPaused, setIsPaused] = useState(true);
  const [player, setPlayer] = useState<typeof JWPlayer>(null);
  const [lastTrackedProgressTime, setLastTrackedProgressTime] = useState<number | null>(null);
  const customOptions = {
    autostart: autoStart,
    image: thumbnailImage
  };
  const hlsOptions = calculateHls();
  const playerOptions = {
    ...DefaultOptions,
    ...hlsOptions,
    ...customOptions
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const musicTrack = useRef<HTMLAudioElement | null>(null);
  const [isVideoPlayerReady, setIsVideoPlayerReady] = useState(false);
  const [soundMixVolume, setSoundMixVolume] = useState(100);
  const [soundMixBalance, setSoundMixBalance] = useState(50);
  const [mixerElementAdded, setMixerElementAdded] = useState(false);
  const previewContainerRoot = useRef<Root | null>(null);

  useEffect(() => {
    const controlbar = document.querySelector(".jw-controlbar");
    const playIcon = controlbar?.querySelector(".jw-icon-playback");

    if (playIcon) {
      playIcon.id = "play-icon";
    }

    if (controlbar) {
      if (isPaused) {
        controlbar.classList.add("paused");
      } else {
        controlbar.classList.remove("paused");
      }
    }
  });

  const playMusic = useCallback(() => {
    if (musicTrack?.current) {
      musicTrack.current.play();
    }
  }, [musicTrack]);

  const pauseMusic = useCallback(() => {
    if (musicTrack?.current) {
      musicTrack.current.pause();
    }
  }, [musicTrack]);

  const loadSoundtrack = useCallback(async (soundtrackId: string) => {
    const soundtrack = await fetchSoundrackById(soundtrackId);
    const audioUrl = soundtrack.urls.full;
    musicTrack.current = new Audio(audioUrl);
  }, []);

  useEffect(() => {
    if (soundtrackId) {
      loadSoundtrack(soundtrackId);
    }

    return () => {
      pauseMusic();
    };
  }, [loadSoundtrack, pauseMusic, soundtrackId]);

  const handlePlayback = useCallback(({ newstate, oldstate }: PlayEvent | PauseEvent) => {
    const isPaused = newstate === "paused" && oldstate === "playing";
    setIsPaused(isPaused);
  }, []);

  const handleDidMountCallback = useCallback(({ player }: { player: typeof JWPlayer }) => {
    setPlayer(player);
  }, []);

  const handleOnPlay = useCallback(
    (event: PlayEvent) => {
      playMusic();
      handlePlayback(event);

      const position = player.getPosition();

      if (typeof eventTracking?.play === "function") {
        eventTracking?.play(event, position);
      }
    },
    [eventTracking, handlePlayback, playMusic, player]
  );

  const handleOnPause = useCallback(
    (event: PauseEvent) => {
      pauseMusic();
      handlePlayback(event);

      const position = player.getPosition();

      if (typeof eventTracking?.pause === "function") {
        eventTracking?.pause(event, position);
      }
    },
    [eventTracking, handlePlayback, pauseMusic, player]
  );

  const handleOnSeek = useCallback(
    (event: SeekEvent) => {
      if (musicTrack?.current) {
        musicTrack.current.currentTime = event.currentTime;

        if (isPaused) {
          pauseMusic();
        } else {
          playMusic();
        }
      }

      if (typeof eventTracking?.seek === "function") {
        eventTracking?.seek(event);
      }
    },
    [eventTracking, isPaused, pauseMusic, playMusic]
  );

  const handleOnProgress = useCallback(
    (event: ProgressEvent) => {
      if (typeof eventTracking?.progress === "function") {
        const secondsPlayed = Math.floor(event.position);

        // This implementation is used to control progress event frequency
        if (secondsPlayed % PROGRESS_EVENT_TRACKING_INTERVAL === 0 && lastTrackedProgressTime !== secondsPlayed) {
          eventTracking?.progress(event);
          setLastTrackedProgressTime(secondsPlayed);
        }
      }
    },
    [eventTracking, lastTrackedProgressTime]
  );

  useEffect(() => {
    if (!player) {
      return;
    }

    const musicTrackGain = 100 - soundMixBalance;
    const videoTrackGain = soundMixBalance;

    let musicTrackVolume = soundMixVolume;
    let videoTrackVolume = soundMixVolume;

    if (musicTrackGain > videoTrackGain) {
      musicTrackVolume = soundMixVolume;
      videoTrackVolume = soundMixVolume * (videoTrackGain / musicTrackGain);
    } else if (musicTrackGain < videoTrackGain) {
      musicTrackVolume = soundMixVolume * (musicTrackGain / videoTrackGain);
      videoTrackVolume = soundMixVolume;
    }

    // Set video track volume
    player.setVolume(videoTrackVolume);

    // Set music track volume
    if (musicTrack?.current) {
      musicTrack.current.volume = musicTrackVolume / 100;
    }
  }, [soundMixBalance, soundMixVolume, musicTrack, player]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.load([{ image: thumbnailImage, sources }]);
    setIsPaused(true);
  }, [player, sources, thumbnailImage]);

  // Handle audio class thumbnail
  useEffect(() => {
    if (!document || !player || !isVideoPlayerReady) return;

    const previewContainer = document.querySelector(".jw-preview");

    if (!previewContainer) return;
    if (!previewContainerRoot.current) previewContainerRoot.current = createRoot(previewContainer);

    const thumbnailComponent = isAudio ? (
      <AudioClassThumbnail imageUrl={thumbnailImage || sources[0]?.file} large />
    ) : (
      <Image
        priority
        className="size-full object-cover"
        sizes="(max-width: 768px) 100vw, 66vw"
        width={800}
        height={600}
        alt=""
        src={thumbnailImage as string}
      />
    );

    previewContainerRoot.current.render(thumbnailComponent);
  }, [isAudio, player, sources, thumbnailImage, isVideoPlayerReady]);

  const handleBeforePlay = useCallback(() => {
    const container = player?.getContainer();

    if (container && soundtrackId && !mixerElementAdded) {
      const mixerElement = document.createElement("div");

      const volumeElement = document.createElement("div");

      const jwVolumeElement = container.querySelector(".jw-controlbar .jw-icon-volume");

      jwVolumeElement.style.display = "none";

      const jwNextElement = container.querySelector(".jw-controlbar .jw-icon-next");

      const jwSoundMixerElement = container.querySelector(`#${soundMixerId}`);

      if (jwNextElement && !jwSoundMixerElement) {
        jwNextElement.parentNode.insertBefore(mixerElement, jwNextElement.nextSibling);

        createRoot(mixerElement).render(<SoundMixer soundMixerId={soundMixerId} onChange={setSoundMixBalance} />);

        jwNextElement.parentNode.insertBefore(volumeElement, jwNextElement.nextSibling);

        createRoot(volumeElement).render(<SoundVolume soundVolumeId={soundVolumeId} onChange={setSoundMixVolume} />);

        setMixerElementAdded(true);
      }
    }
  }, [mixerElementAdded, player, soundtrackId, soundMixerId, soundVolumeId]);

  const handleOnComplete = useCallback(() => {
    pauseMusic();
    onComplete();
  }, [onComplete, pauseMusic]);

  const onOverlayClick = useCallback(() => {
    player.play();
    setIsPaused(false);
  }, [player]);

  useImperativeHandle(ref, () => ({
    isPaused: () => player?.getState() === "paused",
    isPlaying: () => player?.getState() === "playing",
    play: () => {
      player?.play();
      setIsPaused(false);
    },
    pause: () => {
      player?.pause();
      setIsPaused(true);
    }
  }));

  const handleOnReady = useCallback(() => {
    setIsVideoPlayerReady(true);
  }, [setIsVideoPlayerReady]);

  return (
    <div className={cn("relative aspect-[16/9] w-full", classes["jw-player-container"])}>
      <style>
        {`
          .jw-display-icon-container {
            display: none;
          }

          .jwplayer {
            ${videoPlayerContainerStyles ?? ""}
          }

          #play-icon {
            display: flex;
          }
        `}
      </style>

      <JWPlayer
        config={Config}
        library={library}
        sources={sources}
        onBuffer={pauseMusic}
        onComplete={handleOnComplete}
        onSetupError={pauseMusic}
        onBeforePlay={handleBeforePlay}
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        onSeek={handleOnSeek}
        onTime={handleOnProgress}
        onReady={handleOnReady}
        didMountCallback={handleDidMountCallback}
        volume={soundMixVolume}
        {...playerOptions}
      />

      {overlay && (
        <div
          className={cn("s-full [border-radius:8px 8px 0 0] transition-all duration-1000 ease-in-out", {
            "visible opacity-100": isPaused,
            "invisible opacity-0": !isPaused
          })}
        >
          <VideoPlayerOverlay overlayClassName={overlayClassName} onClick={onOverlayClick}>
            {overlay}
          </VideoPlayerOverlay>
        </div>
      )}
    </div>
  );
});

VideoPlayer.displayName = "VideoPlayer";
