export enum SubscriptionStatus {
  Active = "active",
  Cancelled = "cancelled",
  Inactive = "inactive",
  Paused = "paused",
  Trialing = "trialing",
  Hold = "hold"
}

export interface CancellationReason {
  id: string;
  name: string;
}

export interface Link {
  self: string;
}

export interface SubscriptionPlan {
  type: string;
  id?: string;
  subscriptionGroup: number;
  provider: string;
  planPeriod: string;
  planPeriodPrice: number;
  chargeNow: boolean;
  trialPeriodDays: number;
  default: boolean;
  currency: string;
  country: string;
  active: boolean;
  subscriptionGroupId: number;
  variant?: string | null;
  public: boolean;
  lifetimePlan: boolean;
  addonPlan: boolean;
  links: Link;
}

export interface SubscriptionDiscount {
  id: string;
  duration: number;
  amount: number;
  upcomingPlanPrice: number;
  startAt: string;
  endAt?: string;
  type: string;
}

export interface Subscription {
  type: string;
  id: string;
  details?: string | null;
  provider: string;
  providerId: string;
  planId: string;
  discount?: SubscriptionDiscount | null;
  subscribedAt: string;
  periodEndAt: string;
  holdStartAt?: string | null;
  holdEndAt?: string | null;
  trialing: boolean;
  status: string;
  links: Link;
  subscriptionPlan: SubscriptionPlan;
  relationshipNames: string[];
}

export interface UserPaymentSource {
  id: string;
  default: boolean;
  type: string;
  details: {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
  };
}

export type OFFER_REDEMPTION_STATUS = "completed" | "ineligible" | "pending" | "failed";

export interface OfferRedemptions {
  type: string;
  id: number;
  status: OFFER_REDEMPTION_STATUS;
  dateStarted: string;
}

export enum PlanPeriod {
  ANNUAL = "annual",
  MONTHLY = "monthly"
}

export enum WinBackOffer {
  ACCOUNT_HOLD = "account_hold",
  DAYS_FREE_30 = "30_days_free"
}

export type SuspendReason = {
  type: string;
  id: string;
  code: string;
  title: string;
  winbackCopy: null;
  winbackAction: null;
  winbackOffer: WinBackOffer;
  sortOrder: number;
};

export type TPlanID = "12_month_glo_2022" | "1_month_glo_2023";

export interface IPlan {
  id: TPlanID;
  bestValue: boolean;
  name: string;
  price: string;
  discountPrice?: string;
  planNote?: string;
  description: string;
  isDisabled?: boolean;
}
